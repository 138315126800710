export default {
  "datatable": {
    "title": {
      "List of contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contatos"])},
      "List of leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de leads"])},
      "List of prospects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de prospects"])},
      "List of clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de clientes"])},
      "List of orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de pedidos"])},
      "List of expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de despesas"])},
      "List of products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de produtos"])},
      "List of services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de serviços"])},
      "List of incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de ocorrências"])},
      "List of roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cargos"])},
      "List of enterprises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de empreendimentos"])}
    },
    "button": {
      "New contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo contato"])},
      "New prospect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo prospect"])},
      "New lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo lead"])},
      "New client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo cliente"])},
      "New product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo produto"])},
      "New service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo serviço"])},
      "New real estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo imóvel"])},
      "New enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo empreendimento"])},
      "New opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova oportunidade"])},
      "New order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo pedido"])},
      "New contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo contrato"])},
      "New expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova despesa"])},
      "New campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova campanha"])},
      "New content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo conteúdo"])},
      "New incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova ocorrência"])},
      "New FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo FAQ"])},
      "New search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Pesquisa"])},
      "New goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Meta"])},
      "New role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo cargo"])},
      "New attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo anexo"])}
    }
  },
  "header": {
    "title": {
      "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])}
    }
  },
  "CookieConsent": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses cookies to ensure you get the best experience on our website."])},
    "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
  },
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "trade_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade Name"])},
  "matriz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters"])},
  "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "situation_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation Reason"])},
  "legal_nature_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Nature Code"])},
  "activity_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Start Date"])},
  "cnae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnae"])},
  "cnpj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNPJ"])},
  "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
  "rg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RG"])},
  "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity"])},
  "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSN"])},
  "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EIN"])},
  "rne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RNE"])},
  "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type"])},
  "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Number"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
  "person_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person Type"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "cell_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Phone"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
  "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
  "company_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company ID"])},
  "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
  "contact_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
  "city_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City Registration"])},
  "state_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State Registration"])},
  "relationship_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship Type"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "allow_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Marketing"])},
  "allow_interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Interactions"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "secondary_cnaes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Cnae"])},
  "custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])},
  "address_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number (Address)"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "neighborhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neighborhood"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "machine_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Machine)"])},
  "deactivated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated At"])},
  "email_verified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Verified At"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function"])},
  "default_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Route"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "working_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Hour Start"])},
  "working_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Hour End"])},
  "lunch_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch Hour Start"])},
  "lunch_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch Hour End"])},
  "working_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Days"])},
  "work_in_holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work in Holidays"])},
  "block_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Reason"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
  "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
  "Contatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "Lista de pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of orders"])},
  "Lista de contatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of contacts"])},
  "Lista de clientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of clients"])},
  "Lista de despesas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of expenses"])},
  "Lista de leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of leads"])},
  "Lista de produtos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of products"])},
  "Lista de serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of services"])},
  "Lista de ocorrências": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of incidents"])},
  "Lista de cargos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of roles"])},
  "Lista de empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of enterprises"])},
  "Empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprises"])},
  "CustomRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom record"])},
  "Roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "UserFavorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User favorites"])},
  "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "ImportData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data importer"])},
  "SelectOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select options"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "RecordTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record timeline"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
  "Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "NotificationsConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications configuration"])},
  "UserNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User notifications"])},
  "Relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationships"])},
  "ImageSelector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image selector"])},
  "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
  "RealEstateDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate developments"])},
  "DataLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data library"])},
  "WorkflowEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow event"])},
  "Attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
  "EmailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail templates"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "SMTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP"])},
  "RelationshipAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship address"])},
  "StorageDisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage disk"])},
  "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
  "Incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidents"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faqs"])},
  "SupportTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support tickets"])},
  "SettingIncidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident settings"])},
  "StoredFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store filters"])},
  "ProductImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product images"])},
  "Interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactions"])},
  "Reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactions"])},
  "Polls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polls"])},
  "PollVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll votes"])},
  "Opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities"])},
  "SalesFunnels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales funnels"])},
  "FunnelStages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funnel stages"])},
  "SocialMediaPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media posts"])},
  "UserSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User settings"])},
  "SettingsIncidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings incidents"])},
  "Módulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "Registro criado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register created"])},
  "Registro editado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register edited"])},
  "Registro excluído": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register excluded"])},
  "Enviar e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-mail"])},
  "Enviar SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS"])},
  "Lista de modelos de e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of e-mail templates"])},
  "Abaixo, selecione os eventos dos módulos que deseja ser notificado quando acontecerem.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below, select the events of the modules you want to be notified when triggered"])},
  "Serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
}