import axios from 'axios';
import api from "@/services/request";
import service from "@/services/auth";

export default {

    /**
     * Get the user session from local storage as a promise.
     * @returns {Promise<unknown>}
     */
    async getUser() {
        return await service.getStorageItem('user');
    },

    refreshSession() {
        return api.sendUnsafeRequest('get', '/user')
          .then((userResponse) => {
            const userSessionObject = userResponse.data;
            window.localStorage.setItem('user', JSON.stringify(userSessionObject));
            window.dispatchEvent(new CustomEvent('refreshSession', {
                detail: userSessionObject,
            }))
            return userSessionObject;
          })
          .catch((error) => {
            console.error('Error refreshing session:', error);
            throw error;
          })
      },

    /**
     * Get the user session directly from local storage.
     * @returns {}
     */
    getUserSession: function () {
        let session = window.localStorage.getItem('user');
        if (session != null) {
            try {
                var sessionObject = JSON.parse(session);
                if (sessionObject.token != undefined){
                    return sessionObject;
                }
            } catch (e) {
                console.log(e);

            }
        }
        return null;
    },

    /**
     * Execute login api call with the given credentials and return the promise.
     * @param user
     * @returns {Promise<*>}
     */
    async login(user){
        return api.sendUnsafeRequest('post', '/login', JSON.stringify(user));
    },
    
    async verifyUser(data) {
        return api.sendUnsafeRequest('get', '/password-recovery'+data, null)
    },
    
    async createNewResetHash(data) {
        return api.sendUnsafeRequest('post', '/new-reset-hash', data)
    },

    async recoverPassword(data) {
        return api.sendUnsafeRequest('post', '/password-recovery', JSON.stringify(data))
    },

    logout(){
        this.resetAll();
        window.location.assign('#/login');
    },

    resetAll(){
        let theme = window.localStorage.getItem('theme');
        window.localStorage.clear();
        if (theme) {
            window.localStorage.setItem('theme', theme);
        }
    },

    /**
     *
     * @returns {Promise<boolean>}
     */
    async checkLogin() {
        return true;
        let validToken = await this.sendRequest('get', '/resource', '');
        if(validToken.status != 200){
            this.logout();
        }
        return true;
    },

    /**
     * Check if the user is an admin, has to run a request to the server, can't trust the local storage.
     * @returns {Promise<void>}
     */
    async isAdmin() {
        const userRequest =  api.sendRequest('get', '/user')
        userRequest.then((response) => {
            const role = response.data.user.role.machine_name;
            if (role == 'admin') {
                return true;
            }
            return false;
        }, (error) =>
        {
            return false;
        })
    },


}