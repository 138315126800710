import { createStore } from "vuex";

import cache from "@/services/cache";
import sync from "@/services/sync";
import utils from "@/services/utils";

const store = createStore({
  state: {
    initialized: false,
    modal: { value: false },
    variables: {},
    form: {},
    originalForm: {},
    mode: "list",
    conditionals: {},
    filters: {},
    tenant: {},
    menu: {},
    id: null,
    options: {},
    location: {},
    aside: {},
    addingTerm: false,
    theme: localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  },
  mutations: {
    loadFromCache(state, cached) {
      if (cached) {
        Object.keys(cached).forEach((key) => {
          state[key] = Object.assign({}, state[key], cached[key]);
        });
      }

      state.initialized = true;
    },
    updateValue(state, message) {
      //Cria model do campo e salva no store
      if (state.form[message.form.id] == undefined) {
        state.form[message.form.id] = {};
      }
      state.form[message.form.id][message.name] = message.value;
    },
    setModal(state, message) {
      state.modal[message.modalname] = message[message.modalname];
    },
    setForm(state, message) {
      state.form[message.formname] = message[message.formname];
    },
    setOriginalForm(state, message) {
      state.originalForm[message.formname] = message[message.formname];
    },
    resetForm(state, formname) {
      state.form[formname] = state.originalForm["original_" + formname];
    },
    deleteForm(state, message) {
      let stateForm = Object.entries(JSON.parse(JSON.stringify(state.form)));
      let filtered = stateForm.filter(([key, value]) => key !== message);
      state.form = Object.fromEntries(filtered);
    },
    setMode(state, mode) {
      // set permissioning
      state.mode = mode;
    },
    toggleConditional(state, condition) {
      state.conditionals[condition] = !state.conditionals[condition];
    },
    setFilters(state, message) {
      state.filters[message.filter] = message[message.filter];
    },
    updateFilter(state, message) {
      if (state.filters[message.filter.id] == undefined) {
        state.filters[message.filter.id] = {};
      }
      state.filters[message.filter.id][message.name] = message.value;
    },
    setTenant(state, tenant) {
      state.tenant = tenant;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
    setId(state, id) {
      state.id = id;
    },
    setVariables(state, value) {
      state.variables = value;
    },
    setOptions(state, value) {
      state.options[value.name] = value.value;
    },
    resetOptions(state) {
      state.options = {};
    },
    setLocation(state, value) {
      state.location = value;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setAside(state, aside) {
      state.aside[aside] = !state.aside[aside];
    },
    setAddingTerm(state, value) {
      state.addingTerm = value;
    }
  },
  actions: {},
  plugins: [cache, sync],
  modules: {},
  getters: {
    mode(state) {
      return state.mode;
    },
    conditionals(state) {
      return state.conditionals;
    },
    tenant(state) {
      return state.tenant;
    },
    menu(state) {
      return state.menu;
    },
    id(state) {
      return state.id;
    },
    variables(state) {
      return state.variables;
    },
    modal(state) {
      return state.modal.value;
    },
    modalObj(state) {
      return state.modal;
    },
    options(state) {
      return state.options;
    },
    location(state) {
      return state.location;
    },
    theme(state) {
      return state.theme;
    },
    form: (state) => (formName) => {
      return state.form[formName] || null; // Return null if the form is not found
    },
    originalForm: (state) => (formName) => {
      return state.originalForm[formName] || null; // Return null if the form is not found
    },
    aside: (state) => (aside) => {
      return state.aside[aside];
    },
    addingTerm: (state) => {
      return state.addingTerm;
    }
  },
});

export default store;
